/* Footer Styles */
.footer {
    background-color: #ffffff;
    color: #00223b;
    padding: 40px 0;
    margin-top: 5rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.footer-col {
    margin-bottom: 20px;
}

.footer-col h5 {
    font-size: 1.2rem;
    color: #05385f;
    margin-bottom: 15px;
}

.footer-col p,
.footer-col a {
    font-size: 1rem;
    color: #334e62;
    text-decoration: none;
}

.footer-col a:hover {
    color: #05385f;
    text-decoration: none;
}

.social-icons {
    list-style: none;
    padding: 0;
}

.social-icons li {
    display: inline-block; /* Изменили на inline-block для корректного отображения иконок */
    margin-right: 10px;
}

.footer-bottom {
    background-color: #334e62;
    padding: 10px 0;
    color: #99a7b1;
    margin-top: 20px;
    position: relative; /* Добавили position: relative для корректного позиционирования */
    bottom: 0; /* Добавили bottom: 0 для прижимания к нижнему краю */
    width: 100%; /* Добавили ширину для корректного выравнивания */
}

.footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
}

/* Ensure no horizontal scroll on small devices */
body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

/* Custom Styles */
.logoFooter {
    max-width: 100%;
    height: auto;
}

.footer-col ul {
    list-style: none;
    padding: 0;
}

.footer-col ul li {
    margin-bottom: 10px;
}

.footer-col ul li a {
    color: #334e62;
    text-decoration: none;
}

.footer-col ul li a:hover {
    color: #05385f;
}

.menu-footer {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.menu-item-footer {
    margin-right: 20px;
}

.menu-item-footer a {
    text-decoration: none;
    color: #334e62;
    font-size: 1rem;
}

.menu-item-footer a:hover {
    color: #05385f;
}

.menu-item-footer.active a {
    font-weight: bold;
    color: #05385f;
}

.logo-black-footer{
    width: 100px;
    margin-top: -15px;
}

/* @media (min-width: 650px) and (max-width: 1000px) {
    .footer .row{
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .footer-col {
      margin-bottom: 20px;
      width: 100%;
    }
  
    .social-icons {
      justify-content: center;
    }
  
    .social-icons li {
      margin-right: 10px;
    }
    
    .footer-bottom p {
      font-size: 0.8rem; /* Уменьшение размера шрифта для лучшего отображения на маленьких экранах */
    /* }
  } */ 