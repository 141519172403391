.map-image {
  width: 70%;
  max-height: 550px;
  object-fit: cover;
  border-radius: 10px;
}

.cities-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 600px;
  overflow-y: auto;
}

.city-card {
  background-color: #334e62;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

.city-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: white;
}

.city-card p {
  font-size: 1rem;
  margin-bottom: 0;
  color: white;
}

.title-container {
  text-align: center;
  margin-bottom: 20px;
}

.title-card-format {
  position: relative;
  color: #00223b;
  font-size: 2rem;
  font-weight: bold;
  
}

/* Обеспечиваем, что карточки городов выстраиваются в рядок на более широких экранах и в столбик на узких */
.city-card-container {
  display: flex;
  justify-content: center;
}

.cities-container{
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.link-address{
  color: white;
}
.link-address:hover{
  color: rgb(193, 193, 193);
}


@media (min-width: 768px) {
  .city-card-container {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .map-image {
    width: 100%;
  }

  .city-card-container{
    margin-bottom: 1rem;
  }

  .cities-container{
    margin-bottom: 2rem;
    margin-top: 0rem;
  }
}