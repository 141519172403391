.map-container-MapPage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;

}

.map-image-MapPage {
    width: 100%;
    height: auto;
    max-width: 1000px;

}

.city-marker-MapPage {
    position: absolute;
    cursor: pointer;
    width: 75px;
    /* Adjust as necessary */
    height: 80px;
    /* Adjust as necessary */
    /* Just to see the marker, adjust as necessary */
    transform: translate(-50%, -50%);
    z-index: 500;
}

.popup-MapPage {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 900;
    white-space: nowrap;
}

.link-MapPage-address{
    color: black;
}
.link-MapPage-address:hover{
    color: rgb(85, 85, 85);
}

@media (max-width: 768px) {
    .city-marker-MapPage {
        width: 30px;
        height: 30px;
        margin-left: -10px;
    }
}