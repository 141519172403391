.main-content {
    padding: 2rem;
}

.main-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.text-section {
    flex: 2;
}

.image-section {
    flex: 2;
    display: flex;
    justify-content: center;
}

.image-section img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

button {
    margin-top: 1rem;
}

.imgTeacher{
    width: 600px;
}

.advantages-section {
    margin: 50px 0;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .advantages-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .advantages-section ul {
    list-style: none;
    padding: 0;
  }
  
  .advantages-section li {
    margin-bottom: 10px;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #555;
  }
  
