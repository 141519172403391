@import 'aos/dist/aos.css';
.subjects-grid {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    border-collapse: collapse;
}

.subject-block {
    position: relative;
    padding: 3rem 0 3rem 0;
    border: 1px solid #b3b8bd;
    box-sizing: border-box;
    flex: 0 0 25%;
    border-radius: 8px; /* Закругляем углы каждого блока */
}

.subject-block:nth-child(4n)::after {
    border-right: none;
}

.subject-block:nth-child(-n+4)::after {
    border-top: none;
}

.subject-block:nth-child(4) {
    border-right: 1px solid #b3b8bd;
}

.subject-block:nth-last-child(-n+4) {
    border-bottom: 1px solid #b3b8bd;
}

.subject-block:nth-child(4n+1) {
    border-bottom: 1px solid #b3b8bd;
}

.subject-block:nth-child(n+5)::after {
    border-top: none;
}

.subject-block:nth-child(5) {
    border-left: 1px solid #b3b8bd;
}

.subject-block:not(:nth-child(4n+1))::after {
    border-left: none;
}

.feature-icon-subjects {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    color: #334e62;
}

.feature-title-subjects {
    margin-bottom: 10px;
    font-size: 1.8rem;
}

.section-header-subjects {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2rem;
}

.section-title-subjects {
    color: #00223b;
    font-size: 2rem;
    font-weight: bold;
}

.subject-block:hover {
    background-color: #334e6224;
}

.subject-block:hover .feature-title-subjects {
    font-weight: bold;
    color: #00223b;
}
.subject-block:hover .feature-icon-subjects{
    width: 65px;
    height: 65px;
}










/* Стили для модального окна */
.custom-form-modal-dialog {
    max-width: 500px;
    margin: auto;
    /* Минимальная высота модального окна */
    max-height: 100vh;
    /* Максимальная высота модального окна относительно высоты окна браузера */
    
}

.custom-modal-content {
    max-height: 85vh;
    /* Максимальная высота модального окна */
    display: flex;
    flex-direction: column;
    
}

.custom-modal-header {
    position: sticky;
    top: 0;
    background-color: white;
    /* Убедитесь, что цвет фона совпадает с фоном заголовка */
    z-index: 10;
    
}

.custom-modal-body {
    overflow-y: auto;
    flex-grow: 1;
    
}


.textAreaForm {
    width: 100%;
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
    resize: none;
    
}

.textModal {
    font-size: 1rem;
    margin-bottom: 5px;
    
}

/* Стили для фиксации модального окна по центру */
.custom-modal .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.custom-modal .modal-content {
    margin: auto;
}

/* Предотвращение прокрутки страницы при открытом модальном окне */
body.modal-open .modal {
    overflow: hidden;
}

/* Исправление обрезания текста в выпадающем списке */
.custom-form-modal-dialog select.textAreaForm {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ced4da;
    padding-right: 1.5rem;
    /* Увеличиваем отступ справа для стрелки */
    
}

.modal-content {
    max-width: 100%;
}


.custom-info-modal-body {
    background: url("../Images/SubjectsBG5.jpg") no-repeat center center/cover;
    border: 2px solid white;
    color: white;
    height: 2000px;
} 




.custom-form-modal-dialog {
    margin-top: 2rem;
    
}

.modal-body-scrollable {
    max-height: 60vh;
    overflow-y: auto;
}

.custom-dropdown {
    position: relative;
    width: 100%;
}

.dropdown-header {
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
    background-color: #fff;
    color: #999;
}

.dropdown-content {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ced4da;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-item.active {
    background-color: #f0f0f0;
    border: 1px solid #b3b8bd;
}

.checkmark {
    color: green;
    margin-left: 10px;
}





/* Для планшетов (ширина экрана от 768px до 991px) */

@media (min-width: 767px) and (max-width: 991px) {

    .subjects-line1,
    .subjects-line2 {
        margin-top: 5rem;
        /* Уменьшаем отступы между строками предметов */
        font-size: 1.2rem;
        /* Увеличиваем размер шрифта для предметов */
    }

    .feature-title-subjects {
        font-size: 1.4rem;
    }
}

@media (max-width: 767px) {

    .subjects-grid {
        flex-direction: column; /* Один элемент в одной колонке на мобильных устройствах */
        align-items: center; /* Центрируем элементы по центру */
    }

    .subject-block {
        flex: 0 0 100%; /* Каждый блок занимает всю ширину */
    }

    .custom-form-modal-dialog {
        max-width: 500px;
        margin: auto;
    }

    .modal-content {
        max-height: 100vh;
        /* Ограничивает высоту содержимого модального окна */
        overflow-y: auto;
        /* Добавляет скролл для содержимого */
    }

    .custom-modal-footer-subjects{
        padding-bottom: 5rem;
        
    }

    .custom-modal-content {
        max-height: 100vh;
    }
    
}