@font-face {
  font-family: 'Gilroy';
  src: url('./Style/Static/Gilroy-UltraLightItalic.ttf');
}

body {
  margin: 0;
  padding: 0;
  background-color: #f3f4f6; /* Цвет фона страницы */
}

.page-container {
  width: 80%; /* Ширина контейнера страницы */
  max-width: 1200px; /* Максимальная ширина контейнера */
  margin: 0 auto; /* Центрирование контейнера */
  padding: 20px; /* Внутренний отступ контейнера */
}


.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-body {
  display: flex;
  flex: 1;
}

header h1 {
  font-size: 1.9rem;
  margin: 0;
}

.sidebar {
  width: 200px;
  background-color: #00223b;
  color: #fff;
  padding: 2rem 1rem;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.sidebar nav ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.main-content {
  flex: 1;
  padding: 2rem;
}

.main-content h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.main-content p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.main-content button {
  background-color: #334e62;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 2rem;
}

.main-content button:hover {
  background-color: #334e62;
}

.subject-card {
  background-color: #fff;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subject-card h4 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.subject-card p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.subject-card button {
  background-color: #334e62;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  margin-right: 1rem;
}

.subject-card button:hover {
  background-color: #99a7b1;
}


@media (max-width: 767px) {

  .page-container {
    width: 95%; /* Ширина контейнера страницы */
    max-width: 1200px; /* Максимальная ширина контейнера */
    margin: 0 auto; /* Центрирование контейнера */
    padding: 10px; /* Внутренний отступ контейнера */
  }

}