

.hero-company {
    position: relative;
    height: 80vh;
    width: 100%;
    background: url('../Images/CompanyBG2.jpg') no-repeat center center/cover;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.CompanyPageText {
    font-size: 2rem;
    color: white;
    text-align: center;
    z-index: 2;
    font-weight: 600;
}

.timeline {
    position: relative;
    padding: 2rem 0;
}

.timeline-list {
    list-style: none;
    padding-left: 0;
}

.timeline-list li {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
}

.timeline-date {
    font-weight: bold;
    font-size: 1.8rem;
    white-space: nowrap;
}

.timeline-item {
    display: flex;
    align-items: center;
}

.timeline-date-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.timeline-content {
    display: flex;
    align-items: center;
}


.company-header-strip {
    width: 100%;
    height: 5.2rem;
    background-color: #05385f;
}

.li-company-text {
    font-size: 1.1rem;
}

.text-job-company {
    font-size: 1.1rem;
}

/* .bg-container-company{
    background: url("../Images/SubjectsBG5.jpg") no-repeat center center/cover;
    color: white;
  } */

.text-li-job-company {
    text-align: left;
}

.statistics-section-company {
    /* Цвет фона статистического раздела */
    padding: 20px 0;
}

.statistics-section-company {
    background-color: #05385f00;
}

.statistics-item-company {
    text-align: center;
    margin-bottom: 3rem;
}

.statistics-number-company {
    font-size: 2.5rem;
    font-weight: bold;
    color: #334e62;
    margin-right: 10px;
}

.statistics-text-company {
    font-size: 1.25rem;
    font-weight: 500;
}

.centered-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: #00223b;
  }
  
  .centered-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;  /* Можете настроить значение для отступа от заголовка */
  }


  /* Основные стили для всех устройств */
.hero-company {
    height: 80vh;
    background: url('../Images/CompanyBG2.jpg') no-repeat center center/cover;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.CompanyPageText {
    font-size: 2rem;
    color: white;
    text-align: center;
    z-index: 2;
    font-weight: 600;
}

/* Таймлайн */
.timeline-list li {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
}

/* Заголовок секции */
.section-title {
    font-size: 2rem;
}
.text-job-company-meet{
    font-size: 1.3rem;
    color: #00223b;
    font-weight: bold;
}

/* Медиазапросы для адаптивности */
@media (max-width: 768px) {
    .CompanyPageText {
        font-size: 1.5rem; /* Уменьшение размера текста на мобильных устройствах */
    }

    .timeline-date {
        font-size: 1.5rem; /* Уменьшение размера даты */
    }

    .li-company-text h3 {
        font-size: 1.5rem; /* Уменьшение размера заголовков */
    }

    .li-company-text p {
        font-size: 1rem; /* Уменьшение размера текста */
    }

    .statistics-number-company {
        font-size: 2rem; /* Уменьшение размера числа */
    }

    .statistics-text-company {
        font-size: 1rem; /* Уменьшение размера текста */
    }
}

@media (max-width: 576px) {
    .CompanyPageText {
        font-size: 1.2rem; /* Дополнительное уменьшение для очень маленьких экранов */
    }

    .timeline-list li {
        font-size: 1rem; /* Уменьшение размера текста для таймлайна */
    }
}
