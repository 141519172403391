.contacts-section {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
  }
  .contacts-header {
    font-size: 24px;
    font-weight: bold;
    color: #00223b;
  }
  .contacts-text {
    font-size: 16px;
    color: #334e62;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .btn-contacts {
    background-color: #334e62;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .btn-contacts:hover {
    background-color: #99a7b1;
  }
  .contacts-image {
    max-width: 100%;
  }

  .title-contacts{
    color: #00223b;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10rem;
  }
  @media (max-width: 1024px) {

    .title-contacts{
      margin-top: 0rem;
    }

  }

  @media (max-width: 767px) {

    .logo-contacts{
      margin-top: 3rem;
    }

  }
  
  