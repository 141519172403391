body {
    background-color: #f4f4f9;
}

.title-price {
    color: #00223b;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.card-custom {
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 10px;
    margin: 30px;
    border: 0;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.card-custom:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}


.card-header-custom {
    font-size: 1.5rem;
    color: #ffffff;
    background-color: #05385f;
    padding: 15px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.card-icon {
    margin-right: 10px;
    font-size: 1.8rem;
}

.card-custom .card-subtitle {
    font-size: 1.3rem;
    color: #99a7b1;
    margin-bottom: 15px;
}

.card-custom .card-text {
    font-size: 1.1rem;
    color: #334e62;
    margin-bottom: 20px;
}

.card-custom .price {
    font-size: 1.3rem;
    font-weight: bold;
    color: #05385f;
    margin-bottom: 10px;
}

.card-custom .total-price {
    font-size: 1.2rem;
    color: #05385f;
    font-weight: 500;
    margin-bottom: 20px;
}

button {
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

button.btn-primary {
    background-color: #05385f;
    border: none;
}

button.btn-secondary {
    background-color: #334e62;
    border: none;
}

button.btn-success {
    background-color: #00223b;
    border: none;
}

button:hover {
    opacity: 0.9;
    background-color: #00223b;
    color: #ffffff;
}

.block-subject-card {
    margin: 0;
    padding: 0;
}

.second-block-price{
    margin-bottom: 12rem;
}







@media (max-width: 1024px) {

    /* Телефоны и ниже */
    .card-custom {
        width: 100%;
        /* Изменим на 100%, чтобы карточка занимала всю доступную ширину */
        margin: 0 auto;
        /* Центрируем карточку по горизонтали */
        display: flex;
        /* Добавим flex для управления внутренними элементами */
        flex-direction: column;
        /* Столбцовая ориентация для элементов внутри карточки */
        align-items: center;
        /* Центрируем содержимое по центру карточки */
        padding: 0rem;
        /* Добавим небольшие внутренние отступы */
        box-sizing: border-box;
        /* Учтем внутренние отступы в общей ширине */
        border: 1px solid #ccc;
        border-radius: 10px;
        transition: all 0.3s ease;
    }

    .card-custom .card-header-custom {
        font-size: 1.1rem;
        text-align: center;
        /* Центрируем текст заголовка */
    }

    .card-custom .price {
        font-size: 1rem;
        /* Центрируем текст цены */
        font-weight: bold;
        /* Усилим текст цены */
    }

    .card-custom .total-price {
        font-size: 1rem;
        /* Центрируем текст общей цены */
    }

    .text-subject-card {
        font-size: 1rem;
    }

    .title {
        font-size: 1.6rem;
        margin: 0;
    }

    .second-block-price{
        margin-bottom: 0rem;
    }
}