* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
}

ul {
  list-style: none;
  padding: 0;
}

h1, h2, h3, h4, p {
  margin: 0 0 1rem 0;
}

button {
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0.5rem 1rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sidebar ul li {
  padding: 0.5rem 0;
}

.sidebar ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.subject-card {
  background-color: #fff;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
