.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center; /* Распределение элементов по краям */
    padding: 0.5rem 0.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow-x: auto; /* Добавлено для горизонтального скролла */
    white-space: nowrap; /* Запрещает перенос на новую строку */
  }
  
  .header.transparent {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .header.white {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logoHeader {
    height: 50px;
    cursor: pointer;
  }
  
  .menu-container {
    display: flex;
    justify-content: center; /* Выравнивание меню вправо */
    flex-grow: 1;
  }
  
  .menu-header {
    display: flex;
    list-style: none;
    margin: 0;
  }

  ul.menu-header{
    padding-top: 1.1rem;
  }
  
  .menu-item-header a {
    position: relative;
    padding: 1.5rem;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .menu-item-header a.active:not(.company) {
    color: #00223b;
    font-weight: bold;
  }
  
  .header a {
    color: white;
  }
  
  .header.white a {
    color: black;
  }

  .menu-header .active {
    color: #00223b; /* Замените на нужный вам цвет */
  }
  

  .header {
    transition: background-color 0.3s, color 0.3s;
  }
  
  .header.white {
    background-color: white;
  }
  
  .menu-header .active {
    color: #00223b; /* Замените на нужный вам цвет */
  }
  
  /* Дополнительные стили для цвета текста при прокрутке */
  .header.white .menu-header .active {
    color: #00223b !important;
  }
  
  .header.white .menu-header a {
    color: black;
  }
  
  .header.white .menu-header .company.active {
    color: #00223b !important;
    font-weight: bold;
  }
  .header.transparent .menu-header .company {
    color: white;
  }
  
  .header.transparent .menu-header .active {
    color: white;
  }
  

  
  @media (max-width: 768px) {
    .menu-header {
      flex-direction: row;
    }
  
    .menu-container {
      justify-content: flex-start;
    }
  }
  