body.modal-open {
  overflow: hidden;
  padding-right: 0 !important;
}

.modal-body-MainPage {
  max-height: 80vh;
  /* или другая высота для создания контейнера для скроллинга */
  overflow-y: auto;
}

.hero {
  position: relative;
  height: 80vh;
  width: 100%;
  background: url('../Images/ImgBGMain2.jpg') no-repeat center center/cover;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Стили для блока контента на героической секции */
.mainPageText {
  font-size: 4.5rem;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  z-index: 2;
}

.cta-button.btn.btn-primary {
  background-color: #334e62;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.8rem;
  width: 20rem;
  /* Ширина кнопки */
  height: 5rem;
  /* Высота кнопки */
  font-size: 1.75rem;
  /* Увеличен размер шрифта */
  margin-top: 1rem;
  /* Отступ сверху */
  z-index: 2;
}

.cta-button.btn.btn-primary:hover {
  background-color: #99a3a9;
  /* Темнее при наведении */
}

.modal-content {
  position: absolute;
  z-index: 9999;
}

/* Стили для модального окна */
.titleModal {
  font-size: 2rem;
  color: #334e62;
  font-weight: bold;
  text-align: center;
}

.logoAfterText {
  height: 2rem;
}

.textModal {
  font-size: 1.2rem;
  color: #334e62;
}

/* Стили для формы */
.form-control {
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  color: #999;
}

.form-control::placeholder {
  color: #999;
}



.btn-primary {
  background-color: #334e62;
  border-color: #334e62;
  font-size: 1.25rem;
  /* Увеличен размер шрифта */
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
}

.btn-primary:hover {
  background-color: #99a7b1;
  border-color: #99a7b1;
}

.btn-secondary {
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
}

/* Стили для статистического раздела */
.statistics-section {
  background-color: #f3f4f6;
  /* Цвет фона статистического раздела */
  padding: 30px 0;
}

.statistics-item {
  text-align: center;
  margin-bottom: 30px;
}

.statistics-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #334e62;
  margin-right: 10px;
}

.statistics-text {
  font-size: 1.25rem;
  font-weight: 500;
}

/* Стили для PhoneInput */
/* Стили для PhoneInput */
.phone-input-container {
  width: 100%;
  margin-bottom: 1rem;
}

.phone-input-container .phone-input-field {
  width: 100%; /* Уменьшаем ширину, чтобы вместить флаг и код страны */
  padding: 0.75rem 1.25rem; /* Оставляем стандартные отступы */
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ced4da;
  color: #495057;
  padding-left: 3rem; /* Добавляем отступ слева для кода страны */
}

.phone-input-container .phone-input-field:focus {
  
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.phone-input-container .flag-dropdown {
  background-color: transparent; /* или цвет фона вашей формы */
  border: none; /* убираем границу */
}


.phone-input-container .selected-flag {
  background-color: #f5f5f5;
  border-right: 1px solid #ced4da;
  border-radius: 10px 0 0 10px;
  border: 1px solid #ced4da;
  position: absolute; /* Сделать позицию флага абсолютной */
  top: 50%; /* Центрировать по вертикали */
  transform: translateY(-50%); /* Центрировать по вертикали */
   /* Сместить флаг влево, чтобы не перекрывать код страны */
}





@media (max-width: 1000px) and (orientation: landscape) {
  .modal-dialog {
    width: auto !important;
    max-width: none !important;
    height: auto !important;
    max-height: none !important;
    margin: 0 !important;
  }
  .modal-content {
    width: 100vw !important;
    height: 115vh !important;
    max-height: none !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 4rem 2rem 0 2rem!important;
  }
  .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 2rem);
    padding: 1.5rem;
  }
}


/* Медиа-запросы для адаптивности */
@media (max-width: 992px) {
  .hero {
    height: 100vh;
  }

  .mainPageText {
    font-size: 3rem;
  }

  .cta-button {
    width: 100%;
  }

  .statistics-section {
    padding: 40px 0;
  }
}

@media (max-width: 767px) {
  .mainPageText {
    font-size: 2.5rem;
  }

  /* Стили для модального окна на мобильных устройствах */
  .modal-dialog {
    margin: 0;
    /* Убираем отступы вокруг модального окна */
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }

  .modal-content {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    margin: 0;
    /* Убираем все внешние отступы */
  }

  .titleModal {
    font-size: 1.5rem;
    padding: 2rem 0 0 1rem;
    /* Добавляем отступы внутри заголовка */
  }

  .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 2rem);
    /* Учитываем отступы */
    padding: 1.5rem;
    /* Убираем внутренние отступы */
  }
  .custom-modal-footer{
    padding-bottom: 15rem;
  }
}


@media (min-width: 768px) and (max-width: 1025px) {
  .hero {
    background-size: cover !important;
    /* Измените размер фонового изображения для планшетов */
    background-position: center !important;
    /* Центрирование фонового изображения */
    height: 60vh !important;
    /* Уменьшите высоту секции героя для планшетов */
  }

  .mainPageText {
    font-size: 3.5rem !important;
    /* Уменьшите размер текста для планшетов */
    margin: 0 auto !important;
    /* Центрируем текст */
    text-align: center !important;
    width: 80vw !important;
    /* Ширина текста */
  }

  .cta-button {
    width: 18rem !important;
    /* Измените ширину кнопки для планшетов */
    height: 4.5rem !important;
    /* Измените высоту кнопки для планшетов */
    font-size: 1.5rem !important;
    /* Уменьшите размер шрифта для кнопки */
  }
}

@media (max-width: 1000px) and (orientation: landscape) {
  .hero {
    background: url('../Images/ImgBGMain2.jpg') no-repeat center center/cover !important;
    /* Изменяем размер фонового изображения для горизонтальной ориентации */
    height: 100vh !important;
    /* Устанавливаем высоту на весь экран */
    width: 100vw !important;
    /* Устанавливаем ширину на весь экран */
    margin-left: 0 !important;
    /* Убираем левый отступ */
    margin-right: 0 !important;
    /* Убираем правый отступ */
  }

  .mainPageText {
    font-size: 3.5rem !important;
    /* Уменьшаем размер текста для горизонтальной ориентации */
    margin: 0 auto !important;
    /* Центрируем текст */
    text-align: center !important;
    width: 80vw !important;
    /* Ширина текста */
  }

  .cta-button {
    width: 50vw !important;
    /* Изменяем ширину кнопки для горизонтальной ориентации */
    height: 4rem !important;
    /* Изменяем высоту кнопки для горизонтальной ориентации */
    font-size: 1.5rem !important;
    /* Уменьшаем размер шрифта для кнопки */
    margin: 1rem auto !important;
    /* Центрируем кнопку */
    display: block !important;
  }
}