.section-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2rem;
}

.section-title {
  color: #00223b;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.feature-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.mainPage2block {
  margin-bottom: 6rem;
}

.feature-title {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: 600;
}

.feature-text {
  font-size: 1.2rem;
}

/* Анимация */
.feature-item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.feature-item.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Увеличение расстояния между строками */
.gy-4 > .col-md-4 {
  margin-bottom: 5rem; /* Отступ между строками */
}

@media (max-width: 900px) {
  .text-center {
    text-align: center !important;
    /* Исправление выравнивания текста по центру */
  }

  .section-title {
    color: #00223b;
    margin-bottom: 0rem;
  }

  .mainPage2block {
    margin-bottom: 0;
  }

  .feature-icon {
    width: 40px;
    /* Уменьшение размера иконок */
    height: 40px;
  }

  .feature-title {
    font-size: 1.4rem;
    /* Уменьшение размера заголовков */
  }

  .feature-text {
    font-size: 1rem;
    /* Уменьшение размера текста */
  }

  /* Увеличение расстояния между строками на мобильных устройствах */
  .gy-4 > .col-md-4 {
    margin-bottom: 20px; /* Меньший отступ для мобильных устройств */
  }
}
